import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton, MessageBarType, Panel, PanelType, PrimaryButton, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import Param from 'requests/objects/param'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Hunt, { ErrorHunt } from 'requests/objects/hunt'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Candidacy, { ErrorCandidacy } from 'requests/objects/candidacy'
import EBoardCardType from 'types/missions/enums/boardCardType'
import CvInput from 'components/pages/candidats/[id]/cvInput'
import FileInput from 'components/inputs/fileInput'
import FileCv from 'requests/objects/fileCv'
import Time from 'helpers/methods/time'
import { isValidDate } from 'helpers/methods/common'
import resetCarret from 'helpers/methods/resetCarret'
/**
 * Hunt panel
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {boolean} props.isVisible isVisible
 * @param {(value: boolean) => void} props.setIsVisible setIsVisible
 * @param {HuntsHandler | CandidaciesHandler} props.handler handler
 * @param {(item: Hunt | Candidacy) => void} props.onCreate onCreate
 * @param {(item: Hunt | Candidacy) => void} props.onUpdate onUpdate
 * @param {Param} props.param param
 * @param {number} props.missionId missionId
 * @returns {JSX.Element} Returns
 */
export default function BoardUpsertIBoardtemPanel({
    item: itemProps,
    isVisible,
    setIsVisible,
    param,
    handler,
    onCreate,
    onUpdate,
    missionId,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [item, setItem] = useState(itemProps || /** @type {Hunt | Candidacy} */({ missionId, ...new Candidacy(), ...new Hunt() }))
    const [errorField, setErrorField] = useState(/** @type {ErrorHunt | ErrorCandidacy} */({}))
    /** @type {[globalThis.File, React.Dispatch<globalThis.File>]} */
    const [tempFile, setTempFile] = useState(null)

    /** @type {React.MutableRefObject<RequestApi<Hunt | Candidacy>>} */
    const handlerCreateCandidate = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<FileCv>>} */
    const handlerUploadFile = useRef(null)

    /** @type {React.MutableRefObject<HTMLButtonElement>} */
    const submitInputRef = useRef(null)

    const isMerging = useRef(false)

    const upsert = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerCreateCandidate.current = handler.upsert(
                /** @type {any} */(/** @type {Candidacy} */(item) || /** @type {Hunt} */(item)),
                /** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId,
                { isMerging: isMerging.current },
            )
            const boardItem = await handlerCreateCandidate.current.fetch()
            store.dispatch(setMessageBar({ isDisplayed: false }))

            if (item.type === EBoardCardType.CANDIDACY && tempFile)
                try {
                    handlerUploadFile.current = handler.uploadFileCv(
                        /** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId,
                        tempFile,
                    )
                    boardItem.fileCv = await handlerUploadFile.current.fetch()
                    setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté" })
                } catch (error) {
                    setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté mais l'ajout du CV a échoué" })
                }

            setStatus(Status.RESOLVED)
            setIsVisible(false)
            // If has an id update, else create
            if (/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId)
                onUpdate(boardItem)
            else
                onCreate(boardItem)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError: break
                case InvalidEntityError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    setErrorField(/** @type {InvalidEntityError<ErrorHunt>} */(error).errorField)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [handler, item, tempFile, setIsVisible, onUpdate, onCreate])

    // Update local item on props change
    useEffect(() => {
        if (isVisible) {
            setItem(itemProps || /** @type {Hunt | Candidacy} */({ missionId, ...new Candidacy(), ...new Hunt() }))
            setTempFile(null)
            isMerging.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])

    // Update file CV
    useEffect(() => {
        setItem(prevItem => ({ ...prevItem, fileCv: itemProps.fileCv }))
    }, [itemProps.fileCv])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerCreateCandidate?.current?.cancel()
    }, [])

    return (
        <Panel
            // eslint-disable-next-line no-nested-ternary
            headerText={/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId
                ? (item.type === EBoardCardType.CANDIDACY ? 'Modifier une candidature' : 'Modifier un AD')
                : (item.type === EBoardCardType.CANDIDACY ? 'Importer CV' : 'Ajouter un AD')}
            isOpen={isVisible}
            onDismiss={() => setIsVisible(false)}
            onDismissed={() => {
                setErrorField(new ErrorHunt())
            }}
            customWidth="375px"
            type={PanelType.custom}
            closeButtonAriaLabel="Close"
            // eslint-disable-next-line react/no-unstable-nested-components
            onRenderFooterContent={() => (
                <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'flex-start' }}>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        disabled={status === Status.PENDING}
                    >
                        Annuler
                    </DefaultButton>
                    <PrimaryButton
                        onClick={() => {
                            isMerging.current = false
                            submitInputRef.current.click()
                        }}
                        disabled={status === Status.PENDING}
                    >
                        {/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId
                            ? 'Enregistrer'
                            : 'Créer'}
                    </PrimaryButton>
                    <TooltipHost
                        // eslint-disable-next-line max-len
                        content="Si l'email existe déjà, cliquez ici pour déplacer la candidature sur le candidat existant, mettre à jour les données du candidat et supprimer le nouveau candidat. Cette action est définitive."
                        delay={TooltipDelay.zero}
                    >
                        <PrimaryButton
                            onClick={() => {
                                isMerging.current = true
                                submitInputRef.current.click()
                            }}
                            disabled={status === Status.PENDING
                                || !(/** @type {ErrorCandidacy} */(errorField))['candidate.email']?.includes('La valeur existe déja')}
                        >
                            Fusionner
                        </PrimaryButton>
                    </TooltipHost>
                </div>
            )}
            isFooterAtBottom
        >
            <br />
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    upsert()
                }}
            >
                {item.type === EBoardCardType.CANDIDACY && (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        <FilteredVirtualCombobox
                            label="Civilité"
                            readOnly={status === Status.PENDING}
                            options={param.civilities}
                            selectedKey={/** @type {Candidacy} */(item).candidate?.civilityId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, civilityId: option.key } }))}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.civilityId']}
                            required
                        />
                        <TextField
                            label="Nom"
                            value={/** @type {Candidacy} */(item).candidate?.lastname}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, lastname: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.lastname']}
                            required
                        />
                        <TextField
                            label="Prénom"
                            value={/** @type {Candidacy} */(item).candidate?.firstname}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, firstname: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.firstname']}
                            required
                        />
                        <TextField
                            label={`Date de naissance${item?.candidate?.birthdate ? ` (${Time(item?.candidate?.birthdate).getAge()} ans)` : ''}`}
                            value={item?.candidate?.birthdate ? Time(item?.candidate?.birthdate).getLocaleDateString() : ''}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({
                                ...prevValue,
                                candidate: { ...prevValue.candidate, birthdate: isValidDate(new Date(newVal)) ? new Date(newVal) : /** @type {any} */('') },
                            }))}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.birthdate']}
                            type="date"
                        />
                        <TextField
                            label="Entreprise actuelle"
                            value={/** @type {Candidacy} */(item).candidate?.company}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, company: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.company']}
                            required
                        />
                        <TextField
                            label="Poste actuel"
                            value={/** @type {Candidacy} */(item).candidate?.currentPosition || ''}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, currentPosition: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.currentPosition']}
                            required
                        />
                        <TextField
                            label="Profil linkedin"
                            value={/** @type {Candidacy} */(item).candidate?.linkedinProfileUrl || ''}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => setItem(prevValue => ({
                                ...prevValue, candidate: { ...prevValue.candidate, linkedinProfileUrl: newVal },
                            }))}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.linkedinProfileUrl']}
                        />
                        <TextField
                            label="Code postal"
                            value={/** @type {Candidacy} */(item).candidate?.zipCode}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, zipCode: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.zipCode']}
                            required
                        />
                        <FilteredVirtualCombobox
                            label="Ville"
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            options={param.cities}
                            selectedKey={/** @type {Candidacy} */(item).candidate?.cityId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, cityId: option.key } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.city']}
                            required
                        />
                        <TextField
                            label="Téléphone portable"
                            value={/** @type {Candidacy} */(item).candidate?.mobilePhone}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, mobilePhone: newVal } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.mobilePhone']}
                            type="phone"
                            required
                        />
                        <TextField
                            label="Email"
                            value={/** @type {Candidacy} */(item).candidate?.email}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, email: newVal } }))}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.email']
                                // eslint-disable-next-line react/no-danger
                                ? <span dangerouslySetInnerHTML={{ __html: /** @type {ErrorCandidacy} */(errorField)['candidate.email'] }} />
                                : null}
                            type="email"
                            required
                        />
                        <TextField
                            label="RA"
                            value={/** @type {Candidacy} */(item).currentSalary}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, currentSalary: newVal?.toUpperCase() }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField).currentSalary}
                        />
                        <TextField
                            label="RS"
                            value={/** @type {Candidacy} */(item).desiredSalary}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, desiredSalary: newVal?.toUpperCase() }))

                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField).desiredSalary}
                        />
                        {/** @type {Candidacy} */(item).origin
                            ? (
                                <TextField
                                    label="Origine"
                                    value={/** @type {Candidacy} */(item).origin}
                                    readOnly={status === Status.PENDING}
                                    borderless={status === Status.PENDING}
                                    onChange={(ev, newVal) => {
                                        setItem(prevValue => ({ ...prevValue, origin: newVal?.toUpperCase() }))
                                        resetCarret(ev)
                                    }}
                                    errorMessage={/** @type {ErrorCandidacy} */(errorField).origin}
                                    required
                                />
                            )
                            : (
                                <FilteredVirtualCombobox
                                    label="Provenance"
                                    readOnly={status === Status.PENDING}
                                    options={param.candidacyProvenances}
                                    selectedKey={/** @type {Candidacy} */(item).candidacyProvenanceId}
                                    onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, candidacyProvenanceId: option.key }))}
                                    errorMessage={/** @type {ErrorCandidacy} */(errorField).candidacyProvenanceId}
                                    required
                                />
                            )}
                        <TextField
                            label="Commentaire"
                            value={/** @type {Candidacy} */(item).comment}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, comment: newVal?.toUpperCase() }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField).comment}
                            multiline
                            autoAdjustHeight
                        />
                        {(/** @type {Candidacy} */(item).candidacyId)
                            ? (
                                <CvInput
                                    isReadOnly={!(/** @type {Candidacy} */(item).candidacyId)}
                                    id={/** @type {Candidacy} */(item).candidacyId}
                                    handler={/** @type {CandidaciesHandler} */(handler)}
                                    updateItem={fileCv => onUpdate({ ...item, fileCv })}
                                    item={/** @type {Candidacy} */(item)}
                                />
                            )
                            : (
                                <FileInput
                                    label="CV"
                                    labelUpload="Télécharger le cv"
                                    isReadOnly={false}
                                    isDisabled={status === Status.PENDING}
                                    fileName={tempFile?.name}
                                    tooltipContent=""
                                    onDownload={() => null}
                                    onUpload={file => Promise.resolve(setTempFile(file))}
                                    onDelete={() => Promise.resolve(setTempFile(null))}
                                />
                            )}
                    </>
                )}
                {item.type === EBoardCardType.HUNT && (
                    <>
                        <FilteredVirtualCombobox
                            label="Civilité"
                            readOnly={status === Status.PENDING}
                            options={param.civilities}
                            selectedKey={/** @type {Hunt} */(item).candidate?.civilityId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, civilityId: option.key } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.civilityId']}
                        />
                        <TextField
                            label="Nom"
                            value={/** @type {Hunt} */(item).candidate?.lastname}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, lastname: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.lastname']}
                        />
                        <TextField
                            label="Prénom"
                            value={/** @type {Hunt} */(item).candidate?.firstname}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, firstname: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.firstname']}
                        />
                        <TextField
                            label={`Date de naissance${item?.candidate?.birthdate ? ` (${Time(item?.candidate?.birthdate).getAge()} ans)` : ''}`}
                            value={item?.candidate?.birthdate ? Time(item?.candidate?.birthdate).getLocaleDateString() : ''}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({
                                ...prevValue,
                                candidate: { ...prevValue.candidate, birthdate: isValidDate(new Date(newVal)) ? new Date(newVal) : /** @type {any} */('') },
                            }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.birthdate']}
                            type="date"
                        />
                        {' '}
                        <TextField
                            label="Code postal candidat"
                            value={/** @type {Hunt} */(item).candidate?.zipCode}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, zipCode: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.zipCode']}
                            required
                        />
                        <FilteredVirtualCombobox
                            label="Ville candidat"
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            options={param.cities}
                            selectedKey={/** @type {Hunt} */(item).candidate?.cityId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, cityId: option.key } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.city']}
                            required
                        />
                        <FilteredVirtualCombobox
                            label="Département candidat"
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            options={param.departments}
                            selectedKey={/** @type {Hunt} */(item).candidate?.departmentId}
                            // eslint-disable-next-line max-len
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, departmentId: option.key } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.department']}
                            required
                        />
                        <TextField
                            label="Entreprise où chasser"
                            value={/** @type {Hunt} */(item).company}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, company: newVal?.toUpperCase() }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField).company}
                            required
                        />
                        <TextField
                            label="Poste actuel"
                            value={/** @type {Hunt} */(item).candidate?.currentPosition || ''}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({
                                    ...prevValue,
                                    candidate: { ...prevValue.candidate, currentPosition: newVal?.toUpperCase() },
                                }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.currentPosition']}
                            required
                        />
                        <TextField
                            label="Profil linkedin"
                            value={/** @type {Hunt} */(item).candidate?.linkedinProfileUrl || ''}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({
                                ...prevValue, candidate: { ...prevValue.candidate, linkedinProfileUrl: newVal },
                            }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.linkedinProfileUrl']}
                        />
                        <TextField
                            label="Code postal"
                            value={/** @type {Hunt} */(item).zipCode}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, zipCode: newVal?.toUpperCase() }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField).zipCode}
                            required
                        />
                        <FilteredVirtualCombobox
                            label="Ville"
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            options={param.cities}
                            selectedKey={/** @type {Hunt} */(item)?.cityId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, cityId: option.key }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)?.city}
                            required
                        />
                        <FilteredVirtualCombobox
                            label="Département"
                            readOnly={status === Status.PENDING}
                            options={param.departments}
                            selectedKey={/** @type {Hunt} */(item).departmentId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, departmentId: option.key }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField).departmentId}
                            required
                        />
                        <TextField
                            label="Téléphone fixe"
                            value={/** @type {Hunt} */(item).landlinePhone}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, landlinePhone: newVal }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField).landlinePhone}
                            type="phone"
                        />
                        <TextField
                            label="Téléphone portable"
                            value={/** @type {Hunt} */(item).candidate?.mobilePhone}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, mobilePhone: newVal } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.mobilePhone']}
                            type="phone"
                        />
                        <TextField
                            label="Email"
                            value={/** @type {Candidacy} */(item).candidate?.email}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, email: newVal } }))}
                            errorMessage={/** @type {ErrorCandidacy} */(errorField)['candidate.email']
                                // eslint-disable-next-line react/no-danger
                                ? <span dangerouslySetInnerHTML={{ __html: /** @type {ErrorCandidacy} */(errorField)['candidate.email'] }} />
                                : null}
                            type="email"
                        />
                        <FilteredVirtualCombobox
                            label="Provenance"
                            readOnly={status === Status.PENDING}
                            options={param.huntProvenances}
                            selectedKey={/** @type {Hunt} */(item).huntProvenanceId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, huntProvenanceId: option.key }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField).huntProvenanceId}
                            required
                        />
                        <TextField
                            label="RA"
                            value={/** @type {Hunt} */(item).currentSalary}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, currentSalary: newVal?.toUpperCase() }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField).currentSalary}
                        />
                        <TextField
                            label="RS"
                            value={/** @type {Hunt} */(item).desiredSalary}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, desiredSalary: newVal?.toUpperCase() }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField).desiredSalary}
                        />
                        <TextField
                            label="Commentaire"
                            value={/** @type {Hunt} */(item).comment}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(ev, newVal) => {
                                setItem(prevValue => ({ ...prevValue, comment: newVal?.toUpperCase() }))
                                resetCarret(ev)
                            }}
                            errorMessage={/** @type {ErrorHunt} */(errorField).comment}
                            multiline
                            autoAdjustHeight
                        />
                        {!!(/** @type {Hunt} */(item).huntId)
                            && (
                                <CvInput
                                    isReadOnly={!(/** @type {Hunt} */(item).huntId)}
                                    id={/** @type {Hunt} */(item).huntId}
                                    handler={/** @type {HuntsHandler} */(handler)}
                                    updateItem={fileCv => onUpdate({ ...item, fileCv })}
                                    item={/** @type {Hunt} */(item)}
                                />
                            )}
                    </>
                )}
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={submitInputRef}
                    tabIndex={-1}
                />
            </form>
        </Panel>
    )
}
